import { useEffect } from "react";
import images from "../constants/images";
import videos from "../constants/videos";

const Telegram = () => {
  useEffect(() => {
    document.title = "Crickstox - Telegram";
  }, []);

  return (
    <div className="telegram">
      <video src={videos.intro} className="tel-logo" muted autoPlay loop />
      <a
        className="channel"
        target="_blank"
        href="https://t.me/crickstoxfantasy"
      >
        <img src={images.telIcon} />
        Join Telegram Channel
      </a>
    </div>
  );
};

export default Telegram;

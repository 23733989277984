import { useEffect, useRef } from "react";
import images from "../constants/images";

import { useDimensions } from "react-dimensions-hook";
import { Link } from "react-router-dom";

const Home = ({ bgIcon = images.listboxOption }) => {
  const {
    dimensions: { width },
    ref,
  } = useDimensions();

  const downloadRef = useRef();

  const cart = [
    {
      title: "Cash Out for Team Stakes",
      images: images.cashOut,
      text: "Cash out team stakes mid-match for optimized winnings based on real-time performance.",
      tips: "Play only those games that you have a fair amount of knowledge about",
      img: images.img1,
    },
    {
      title: "Every Ball",
      images: images.ballToBall,
      text: "Experience every delivery, boundary, and wicket. Don't miss a moment of the action-packed excitement!",
      tips: "Before playing, check the weather report, pitch report, average score on the ground, probable 11, their past performances (overall and against the competing team), strike rates, economy rates, etc.",
      img: images.img2,
    },
    {
      title: "Buy Players",
      images: images.buyPlayers,
      text: "You have to buy the best players of both the teams who can give you more profits. Understand points in points system.",
      tips: "Detailed statistics are available on the Crickstox app itself that can be analysed",
      img: images.img3,
    },
    {
      title: "Contest 1vs1 Player",
      images: images.contest,
      text: "Compete one-on-one in thrilling cricket challenges. Showcase skills, claim victory!",
      tips: "Read through news items, if any, pertaining to the teams that may impact the balance of the teams, e.g. player injuries, rest, etc.",
      img: images.img4,
    },
    {
      title: "Run Rate Scalping",
      images: images.runRate,
      text: "Capitalize on fluctuating rates. Strategize chasing targets or setting totals for maximum success in every match!",
      tips: "Join the mega contest as soon as it is available on the app (generally, 48 hours prior to the match being played) as the spots get filled up quickly",
      img: images.img5,
    },
    {
      title: "Bonuses",
      images: images.bonuses,
      text: "Unlock bonuses by achieving milestones. Add excitement to your fantasy cricket experience and incentivize engagement and success with rewarding surprises!",
      tips: "Explore the ‘Winners’ section of the Crickstox app to understand the kind of teams being created by various winners",
      img: images.img6,
    },
  ];

  const box = [
    {
      text: "Select the match: Once on the main page, you can pick a match from the many upcoming matches.",
    },
    {
      text: "Before Play Contest & Buy Players Choose players from 2 teams. Remember to make the best use of your skills here!",
    },
    {
      text: "Before the match goes live, check Lineup & whether your player is playing or not.",
    },
    {
      text: "If your player is not playing and you have bought him, then that amount will be returned to your wallet.",
    },
    {
      text: "Enter the contests: Pick a contest from a wide variety of ongoing contests. you have also option which player in your opponents. just scrolling contest right side to more views",
    },
    {
      text: "Join the contest until the match deadline, Roundlock or the actual match start time.",
    },
    {
      text: "Track buy players and your participate contest progress: Watch the match LIVE to see a player's progress.",
    },
    {
      text: "Winner Declaration: Once a match is complete, winners are declared for the match and winnings are given.",
    },
  ];

  const tips = [
    {
      tips: "Although a no-brainer but you should pick only those players who are in the playing eleven",
    },
    {
      tips: "Do an in-depth and thorough analysis as per your knowledge and skills to make your Crickstox",
    },
    {
      tips: "Try to join with multiple teams",
    },
    {
      tips: "Do not rely on videos or tips published by untrustworthy websites for creating your team. Use your own knowledge about cricket",
    },
  ];

  const reviewer = [
    {
      name: "Rajat Sharma",
      img: images.user,
      teamName: "MI vs CSK",
      rupees: "3 Lacks",
      text: "CrickStox is hands down the best cricket fantasy app out there! The user interface is seamless, making Player's selection a breeze. The thrill of competing against others for a chance to win 3 lakhs is simply unmatched. Kudos to the developers for creating such an addictive and enjoyable platform!",
    },
    {
      name: " Nick Thakkar",
      img: images.user,
      teamName: "DC vs PBKS",
      rupees: "10 Lacks",
      text: "CrickStox has become my go-to fantasy cricket app. The variety of contests and the potential to win substantial amounts, my winnings 10 lakhs, keeps the excitement alive. The app is user-friendly, and the live updates make the entire experience immersive. Highly recommended for all cricket enthusiasts!",
    },
    {
      name: "Aryan Singh",
      img: images.user,
      teamName: "RR vs RCB",
      rupees: "2 Lacks",
      text: "As a seasoned fantasy cricket player, CrickStox stands out for its blend of skill and luck. The app's algorithm ensures fair play, and the chance to win significant amounts, is 2 lakhs, adds an extra layer of excitement. A must-try for anyone passionate about cricket and fantasy gaming.",
    },
    {
      name: "Heema Rajpurohit",
      img: images.user,
      teamName: "SRH vs GT",
      rupees: "4 Lacks",
      text: "CrickStox has nailed it with its user-friendly interface. Selecting players and creating teams is intuitive, making the overall experience enjoyable. What's more thrilling is the possibility of winning 4 lakhs! This app has certainly redefined the fantasy cricket landscape in India.",
    },
    {
      name: "Anand Jain",
      img: images.user,
      teamName: "KKR vs LSG",
      rupees: "18 Lacks",
      text: "CrickStox has made fantasy cricket more rewarding than ever. The variety of contests caters to all levels of players, and the potential to win up to 18 lakhs is a game-changer. The real-time updates and engaging gameplay make it a top choice for cricket fans seeking both fun and excitement.",
    },
    {
      name: "Sunidhi Verma",
      img: images.user,
      teamName: "MI vs GT",
      rupees: "3 Lacks",
      text: " CrickStox has become my favorite fantasy cricket app. The interface is sleek, and the contests are diverse, ensuring there's something for everyone. The chance to win substantial amounts, up to 3 lakhs, adds an adrenaline rush to every match. I can't get enough of the excitement this app brings!",
    },
    {
      name: "Rohan Patel",
      img: images.user,
      teamName: "CSK vs GT",
      rupees: "50 Lacks",
      text: "CrickStox has taken fantasy cricket to new heights. The app's design is flawless, and the thrill of competing for prizes, including the opportunity to win up to 50 lakhs, is unbeatable. Whether you're a casual player or a serious fantasy cricket enthusiast, CrickStox is the place to be.",
    },
    {
      name: "Axay Gamming",
      img: images.user,
      teamName: "RR vs CSK",
      rupees: "12 Lacks",
      text: "CrickStox has become a part of my daily routine. The app's interface is user-friendly, making it easy to navigate. The contests are well-structured, and the prospect of winning up to 12 lakhs keeps me coming back for more. If you're a cricket lover, CrickStox is a fantasy app you can't afford to miss!",
    },
    {
      name: " Rahul Mewara",
      img: images.user,
      teamName: "KKR vs SRH",
      rupees: "20 Lacks",
      text: "CrickStox is a game-changer in the world of fantasy cricket. The strategic elements involved in team selection make every contest a unique challenge. The icing on the cake is the chance to win significant amounts, up to 20 lakhs. This app rewards skill and keeps the excitement alive throughout the cricket season.",
    },
    {
      name: "Aishwarya Singh",
      img: images.user,
      teamName: "RCB vs PBKS",
      rupees: "1 Lacks",
      text: "CrickStox has exceeded my expectations in every aspect. The potential to win up to 1 lakhs is a major draw, but it's the overall experience that makes this app exceptional. The thrill of competing against fellow cricket fans, coupled with the seamless interface, makes CrickStox a definite winner in the fantasy cricket arena!",
    },
  ];

  const testimonialsRef = useRef();

  const scrollToDownload = () => {
    if (downloadRef.current) downloadRef.current.scrollIntoView();
  };

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (testimonialsRef.current) {
        testimonialsRef.current.scrollLeft += 370;
        if (
          testimonialsRef.current.scrollLeft >=
          testimonialsRef.current.scrollWidth -
            (testimonialsRef.current.clientWidth + 10)
        ) {
          testimonialsRef.current.scrollLeft = 0;
        }
      }
    }, 2000);
    return () => clearInterval(scrollInterval);
  }, []);

  return (
    <div className="Home">
      <div className="bg">
        <div className="head-admin">
          <div>
            <img src={require("../assets/images/logo.png")} className="logo" />
          </div>
          <div className="language">
            <div className="lag-text">English</div>
            <div>
              <img
                src={require("../assets/images/down.png")}
                className="down"
              />
            </div>
          </div>
        </div>
        <div className="container-header">
          <div>
            <img
              src={require("../assets/images/team-bg.png")}
              className="team-bg"
            />
          </div>
          <div className="dow-app">
            <h2>“Ab team nahi, dream banao!”</h2>
            <h3>via CRICKSTOX</h3>
            <h4>
              Play <span>Player Vs Player</span> contest & Make Your Day
            </h4>
            <div className="mobail-num">
              <button
                onClick={() =>
                  window.open(
                    "https://apis.crickstox.com/uploads/crickstox.apk"
                  )
                }
              >
                DOWNLOAD APP
              </button>
              <a href="https://app.crickstox.com/" target="_blank">
                <button>PLAY ON WEB</button>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="startPlay">
        <div className="playing">
          It’s easy to start playing <span>on Crickstox</span>
        </div>
        <div className="fantasy">
          <h4>Crickstox</h4>
          <img src={require("../assets/images/left.png")} className="left" />
          <h5>Fantasy Cricket</h5>
        </div>
        <div className="text">
          Enter into the thrilling world of Fantasy, a strategy-based online
          sports game wherein you can Buy a virtual of real playing in real life
          matches > Pick your best players to win points based on all the
          players performance in a live game
        </div>

        <div className="box-content">
          {cart.map((item, i) => (
            <div className="upcoming-mache">
              <img src={item.images} className="cashOut" />
              <div style={{ marginTop: "100px" }}>
                <div className="cash-out">
                  <div className="number">{i + 1}</div>
                  <img src={item.img} className="img1" />
                </div>
                <div className="team-stakes">
                  <div className="team-title">{item.title}</div>
                  <div className="team-text">{item.text}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="win-more" ref={downloadRef}>
        <div className="win">
          Get the app and <span>Win More</span>
        </div>
        <div className="down-app">
          <img src={images.plyer} className="plyer-img" />
          <div className="down-scan">
            <div className="contest">
              <img src={images.game} className="game-icon" />
              <div className="win-text">
                Play with over 1L+ CRICKSTOX user or
                <span>create your own private contest</span>
              </div>
            </div>
            <div className="contest">
              <img src={images.plyerAdd} className="game-icon" />
              <div className="win-text">
                Invite your friends & collect big rewards
              </div>
            </div>
            <div className="contest">
              <img src={images.rewards} className="game-icon" />
              <div className="win-text">
                Play more, earn Crickstox big rewards
              </div>
            </div>
            <div className="down-type">
              <a href="https://apis.crickstox.com/uploads/crickstox.apk">
                <img src={images.app} className="app-icon" />
              </a>
              <div>
                <img src={images.qr} className="qr-icon" />
              </div>
              <div className="qr-text">
                Scan the QRCode to download the Crickstox App
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="testimonials">
        <h3>Testimonials</h3>
        <div className="user-box" ref={testimonialsRef}>
          {reviewer.map((item, index) => (
            <div className="win-box" key={index}>
              <div className="user-win">
                <div className="user">
                  <div className="abc">
                    <img src={item.img} className="user-icon" />
                  </div>
                  <div className="user-name">{item.name}</div>
                </div>
                <div className="rupees-win">
                  <div className="mega-win">
                    Player vs Player Contest Winner <span>{item.teamName}</span>
                  </div>
                  <div className="rupee">
                    ₹ {item.rupees} <span>WINNINGS</span>
                  </div>
                </div>
                <p>{item.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="app-safe">
        <div className="app-title">
          Getting Started with Fantasy Cricket App -
          <span>It's free and 100% safe!</span>
        </div>
        <div className="app-text">
          Log on to the <span>Crickstox fantasy app</span>, and follow the
          below-given steps to participate in this game of thrill and skill!
        </div>

        <div className="box-con">
          {box.map((item, i) => (
            <div className="select-box">
              <div className="box-num">{i + 1}</div>
              <div className="box-text">{item.text}</div>
            </div>
          ))}
        </div>

        <div className="follow">
          <div className="follow-text">
            Follow <span>Crickstox</span> For Today’s Fantasy Cricket Match
            Prediction
          </div>
        </div>
      </div>

      <div className="operates">
        <div className="operate-app">
          <img src={images.indImg} className="limit-img" />
          <h2>Place we operate</h2>
          <p>
            Our fantasy cricket app operates across India, excluding the Union
            Territories of Jammu & Kashmir, Odisha, Andhra Pradesh, Telangana,
            Assam, Sikkim, and Nagaland. Download now to experience thrilling
            gameplay in your state.
          </p>
        </div>
        <div className="operate-app">
          <img src={images.limit} className="limit-img" />
          <h2>21 age limit</h2>
          <p>
            At multiple entry points, users are warned about the risks of
            compulsive gaming habits leading to gambling addiction. We actively
            discourage excessive play and provide resources for gambling
            addiction support services.
          </p>
        </div>
        <div className="operate-app">
          <img src={images.rupees} className="limit-img" />
          <h2>Money protection</h2>
          <p>
            Your money's security is our priority. We use encrypted payment
            gateways, fraud detection, stake limits, and insured custody
            accounts to safeguard funds. Personal and financial data is
            encrypted end-to-end. Rest assured, your money is 100% safe.
          </p>
        </div>
      </div>

      <div className="do-dont">
        <div className="app-title">
          <span>Do’s and Don'ts</span> While Playing Fantasy Cricket
        </div>
        <div className="dont-text">
          In reality, playing fantasy cricket is simple, provided you know the
          sport and have a strong analytical bent of mind. It’s truly a game of
          skill. So, before you get excited to play, here are some do's and
          don'ts of playing fantasy cricket.
        </div>
        <div className="dont">
          <div className="do">
            <img src={images.do} className="dont-icon" />
            <div className="do-text">
              <div className="fantasy-text">
                Keep patience and constant perseverance for long-term rewards
              </div>
              <div className="fantasy-text">
                Keep track of the probable playing XI
              </div>
              <div className="fantasy-text">
                Make a group based on solid research
              </div>
              <div className="fantasy-text">Pick matches wisely</div>
              <div className="fantasy-text">
                Wait for the toss before making team changes
              </div>
              <div className="fantasy-text">Keep match schedules handy</div>
            </div>
          </div>
          <div className="do">
            <img src={images.dont} className="dont-icon" />
            <div className="do-text">
              <div className="fantasy-text">
                Don't play with all your money in one match
              </div>
              <div className="fantasy-text">
                Don't pick players based on popularity
              </div>
              <div className="fantasy-text">
                Don't overlook pitch conditions
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="win-more">
        <div className="quit-title">
          Welcome to the Best of
          <span>Fantasy Cricket on Crickstox</span>
        </div>

        <div className="quit-box">
          <div className="quit">
            <h1>What is the fantasy cricket </h1>
            <h2>format of Crickstox</h2>
            <p>
              One of the standout features that sets CrickStox apart is its Ball
              to Ball, Sessions, Buy Players, Player vs Player Contest & Runrate
              Up Down betting option. Unlike traditional fantasy platforms where
              your predictions are based on the entire match, Crickstox allows
              you to place bets on specific deliveries. This level of
              granularity adds an extra layer of excitement, requiring users to
              analyze the game situation, player form, and pitch conditions with
              precision.
            </p>
          </div>
          <div className="quit">
            <h1>How to play fantasy cricket </h1>
            <h2>on Crickstox app?</h2>
            <p>
              On Crickstox, you can opt for standard cricket formats in T20, ODI
              & Tests, and different domestic leagues, international
              tournaments. Fantasy cricket is a game of skill that requires
              research skills and an analytical mind. You must have a fair
              understanding of cricket and research skills to win the game.
              Crickstox makes it easier with match analysis and player
              statistics to help you compete with other players and win exciting
              prizes.
            </p>
          </div>
          <div className="quit">
            <h1>Why play fantasy cricket on </h1>
            <h2>Crickstox</h2>
            <p>
              Crickstox is world's first ball to ball and more advance features
              biggest fantasy sports platform. we are introducing to Great
              Features for all crickstox user's On Crickstox, all fantasy
              players enjoy the best offers and promotions with secure
              transactions. More on this below.
            </p>
          </div>
        </div>
      </div>

      <div className="cricket-tips">
        <div className="tips-title">
          Fantasy Cricket
          <span>Tips</span>
        </div>
        <h4>
          We are breaking this section down into two parts - before the toss and
          after the toss
        </h4>
        <h4>Here are things you should do before the toss:</h4>

        <div>
          {cart.map((item, index) => (
            <div className="tips">
              <img src={images.done} className="tips-icon" />
              <div className="tips-text">{item.tips}</div>
            </div>
          ))}
          <div className="tips-text">
            Things to do after the toss (and team announcements):
          </div>
          {tips.map((item, index) => (
            <div className="tips">
              <img src={images.done} className="tips-icon" />
              <div className="tips-text">{item.tips}</div>
            </div>
          ))}
        </div>
        <div className="tips-text">
          The more you play, the more you will learn. Once you keep playing, you
          do get a better understanding of the format of Fantasy cricket. If you
          want more detailed tips, head over to{" "}
          <span>Fantasy cricket tips.</span>
        </div>
      </div>

      <div class="footer_top">
        <div class="footer_container">
          <div class="left_col1">
            <img src={images.logo} className="footer_logo" />
            <div class="footer_social">
              <a>
                <img src={images.facebook} className="insta-icon" />
              </a>
              <a>
                <img src={images.twitter} className="insta-icon" />
              </a>
              <a>
                <img src={images.linkedin} className="insta-icon" />
              </a>
              <a>
                <img src={images.youtube} className="insta-icon" />
              </a>
              <a>
                <img src={images.instagram} className="insta-icon" />
              </a>

              <a>
                <img src={images.telegram} className="insta-icon" />
              </a>
            </div>
          </div>

          <div class="right_col1">
            <div class="left_link_list">
              <ul>
                <li>
                  <a>Fantasy Cricket</a>
                </li>
                <li>
                  <a>How to play</a>
                </li>
                <li>
                  <a>Fantasy Cricket App Download</a>
                </li>
                <li>
                  <a>Dream11 winners</a>
                </li>
                <li>
                  <a>Private Contest</a>
                </li>
              </ul>
            </div>
            <div class="right_link_list">
              <ul>
                <li>
                  <a>About Us</a>
                </li>
                <li>
                  <a>Helpdesk</a>
                </li>
                <li>
                  <a>Community Guidelines</a>
                </li>
                <li>
                  <a>Crickstox Tech</a>
                </li>
              </ul>
            </div>
          </div>

          {/* <div class="member-section">
                        <div class="membership mb44">
                            <div>
                                <a>FOUNDING MEMBER</a>
                            </div>

                            <img
                                src={images.listItem}
                                className="member-icon"
                            />
                        </div>
                        <div class="membership">
                            <div>
                                <a>PLAY RESPONSIBLY</a>
                            </div>

                            <img
                                src={images.logoWebp}
                                className="member-icon"
                            />
                        </div>
                    </div> */}
        </div>

        <div class="office_address">
          <div>CORPORATE OFFICE</div>
          <div class="address">77460 Helen Port, West Cruz, LA 31283</div>
        </div>
      </div>

      <div class="footer_bottom">
        <div>
          <a>Privacy Policy</a>
        </div>
        <div>
          <a>Terms & Conditions</a>
        </div>
      </div>
    </div>
  );
};

export default Home;

const images = {
  ballToBall: require("../assets/images/ball-to-ball.png"),
  bonuses: require("../assets/images/bonuses.png"),
  buyPlayers: require("../assets/images/buy-players.png"),
  cashOut: require("../assets/images/cash-out.png"),
  contest: require("../assets/images/contest.png"),
  down: require("../assets/images/down.png"),
  game: require("../assets/images/game.png"),
  indImg: require("../assets/images/ind-img.png"),
  left: require("../assets/images/left.png"),
  listboxOption: require("../assets/images/listbox-option.png"),
  listItem: require("../assets/images/list-Item.png"),
  logoWebp: require("../assets/images/logo-webp.png"),
  plyer: require("../assets/images/plyer.png"),
  plyerAdd: require("../assets/images/plyer-add.png"),
  plyerBat: require("../assets/images/plyer-bat.png"),
  plyerImg: require("../assets/images/plyer-img.png"),
  qr: require("../assets/images/qr.png"),
  do: require("../assets/images/do.png"),
  dont: require("../assets/images/dont.png"),
  done: require("../assets/images/done.png"),
  rewards: require("../assets/images/rewards.png"),
  runRate: require("../assets/images/run-rate.png"),
  rupees: require("../assets/images/rupees.png"),
  logo: require("../assets/images/logo.png"),
  app: require("../assets/images/app.png"),
  limit: require("../assets/images/limit.png"),
  user: require("../assets/images/user.png"),
  img1: require("../assets/images/img-1.png"),
  img2: require("../assets/images/img-2.png"),
  img3: require("../assets/images/img-3.png"),
  img4: require("../assets/images/img-4.png"),
  img5: require("../assets/images/img-5.png"),
  img6: require("../assets/images/img-6.png"),
  teamBg: require("../assets/images/team-bg.png"),
  downIcon: require("../assets/images/down-icon.png"),
  upIcon: require("../assets/images/up-icon.png"),
  facebook: require("../assets/images/facebook.png"),
  instagram: require("../assets/images/instagram.png"),
  linkedin: require("../assets/images/linkedin.png"),
  telegram: require("../assets/images/telegram.png"),
  twitter: require("../assets/images/twitter.png"),
  youtube: require("../assets/images/youtube.png"),
  cx: require("../assets/images/cx.png"),
  telIcon: require("../assets/images/tel_icon.png"),
};

export default images;

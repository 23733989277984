import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "../pages/home";
import Telegram from "../pages/telegram";

const Navigation = () => {
  return (
    <BrowserRouter>
      <div className="container">
        <main>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/telegram" element={<Telegram />} />
          </Routes>
        </main>
      </div>
    </BrowserRouter>
  );
};

export default Navigation;
